<template>
  <template v-if="!project">
    <SkeletonLoader :rows="26" />
  </template>
  <template v-else>
    <a v-if="project"
    :href="`/projects/single-project?id=${project.id}`"
    class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
    aria-label="Single Project"
  >
    <div>
      <LazyImage
        :src="project.img.url"
        :alt="project.header.title"
        customClass="rounded-t-xl border-none w-full h-[350px]"
      />
    </div>
    <div class="text-center px-4 py-6">
      <p
        class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
      >
        {{ project.header.title }}
      </p>
      <span
        class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
      >
        {{ project.category }}
      </span>
    </div>
  </a>
  </template>
</template>

<script>
export default {
  props: {
    project: Object,
  },
};
</script>

<style lang="scss" scoped></style>
